import { ReactNode } from 'react';

import i18n from 'i18next';

import { DEFAULT_LANGUAGE_KEY, enabledLanguages } from '@/i18n';

import { RoutesEnum } from '@/enums/routes.enum';

export interface IRoutes {
  path: RoutesEnum;
  element: ReactNode;
  children?: IRoutes[];
}

type TArgsWithParams = { path: RoutesEnum | string; params?: any };

export const getPathWithLang = (
  isValidLang: boolean,
  basePath: string,
  args: TArgsWithParams,
) => {
  const isValidAppLang = ['en', 'fr'].includes(
    basePath as (typeof enabledLanguages)[0],
  );
  if (!isValidAppLang && args.path !== RoutesEnum.rules) {
    return `/${DEFAULT_LANGUAGE_KEY}/${args.path}`;
  }
  if (isValidLang) return `/${basePath}/${args.path}`;
  return `/${DEFAULT_LANGUAGE_KEY}/${args.path}`;
};

export const createPath = (args: TArgsWithParams): string => {
  const basePath = window.location.pathname.split('/')[1]; // This needs to leave path till lang param
  const isValidLang = enabledLanguages.includes(
    basePath as (typeof enabledLanguages)[0],
  );

  const fullPath = getPathWithLang(isValidLang, basePath, args);

  if (!args.hasOwnProperty('params')) return fullPath;

  return Object.entries((args as TArgsWithParams).params).reduce(
    (previousValue: string, [param, value]) =>
      previousValue.replace(`:${param}`, '' + value),
    fullPath,
  );
};
