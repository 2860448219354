export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL;

export const AUTH_APP_CODE = process.env.REACT_APP_AUTH_APP_CODE;

export const GAMEZONE_URL = process.env.REACT_APP_GAMEZONE_URL;

export const ENABLED_LANGUAGES =
  process.env.REACT_APP_ENABLED_LANGUAGES || 'en';

export const SHARE_URL = process.env.REACT_APP_SHARE_URL;

export const ELIGIBLE_LEAGUE_ID = process.env.REACT_APP_ELIGIBLE_LEAGUE_ID;

export const NOT_ELIGIBLE_LEAGUE_ID =
  process.env.REACT_APP_NOT_ELIGIBLE_LEAGUE_ID;

export const ELIGIBLE_SECOND_CHANCE_LEAGUE_ID =
  process.env.REACT_APP_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID;

export const NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID =
  process.env.REACT_APP_NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID;

export const GAME_LINK_LINES = process.env.REACT_APP_GAME_LINK_LINES;

export const GAME_LINK_STATS_STREAK =
  process.env.REACT_APP_GAME_LINK_STATS_STREAK;

export const GAME_LINK_PEPSI = process.env.REACT_APP_GAME_LINK_PEPSI;

export const GAME_LINK_PRE_PLAY_CA =
  process.env.REACT_APP_GAME_LINK_PRE_PLAY_CA;

export const GAME_LINK_PRE_PLAY_US =
  process.env.REACT_APP_GAME_LINK_PRE_PLAY_US;

export const GEOLOCATION_KEY = process.env.REACT_APP_GEOLOCATION_KEY;

export const IS_PROD = process.env.REACT_APP_IS_PROD === 'true';
