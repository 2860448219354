import React, { useCallback } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { ApiTagsEnum } from '@/enums/apiTags.enum';
import { RoutesEnum } from '@/enums/routes.enum';

import { useToast } from '@/hooks/useToast';

import { DeleteLeagueModal } from '@/modules/LeagueManage/components/DeleteLeagueModal';
import {
  getIsManageMode,
  getLeagueData,
} from '@/modules/LeagueManage/leagueManage.selectors';
import { TOGGLE_IS_MANAGE_MODE } from '@/modules/LeagueManage/leagueManage.slice';
import { LeagueInfo } from '@/modules/LeagueManage/leagueManage.types';
import { useDeleteLeagueMutation } from '@/modules/Leagues/leagues.api';

import { createPath } from '@/routes/types';

import { api } from '@/services/api.service';

export const DeleteLeague: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isManageMode: boolean = useSelector(getIsManageMode);
  const leagueData: LeagueInfo | undefined = useSelector(getLeagueData);

  const toast = useToast();

  const [removeUser, { isLoading: isSubmitting }] = useDeleteLeagueMutation();

  const leagueId: string | undefined = leagueData?.league_id;

  const handleDeleteLeague = useCallback(async () => {
    try {
      await removeUser({ league_id: leagueId as string }).unwrap();
      toast(t(tKeys.yourLeagueDeleted), 'success');
      dispatch(TOGGLE_IS_MANAGE_MODE());
      dispatch(api.util.invalidateTags([ApiTagsEnum.GET_LEAGUE_ENTRY]));
      navigate(
        createPath({
          path: RoutesEnum.leagues,
        }),
      );
    } catch (error) {
      toast(error?.data?.message || error?.message, 'error');
    }
  }, [dispatch, leagueId, navigate, removeUser, t, toast]);

  if (!isManageMode || !leagueId) {
    return null;
  }

  return (
    <DeleteLeagueModal
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onClose={onClose}
      onDelete={handleDeleteLeague}
      onOpen={onOpen}
    />
  );
};
