import { FC } from 'react';

import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import { SeriesResultsType } from '@/modules/Bracket/bracket.types';
import { AnswerButton } from '@/modules/Bracket/components/BonusModal/AnswerButton';
import { SquareTeamCard } from '@/modules/Bracket/components/BonusModal/SquareTeamCard';
import { BONUS_GAMES_NUMBERS } from '@/modules/Bracket/helpers/constants';
import { useQuestionMatch } from '@/modules/Bracket/hooks/useQuestionMatch.hook';

export const QuestionMatchBlock: FC<SeriesResultsType> = ({
  id,
  team_1_id,
  team_2_id,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { clickHandler, entry, key, selectedTeam, entrySC } =
    useQuestionMatch(id);

  return (
    <Flex
      backgroundColor={{ base: 'rgba(0, 0, 0, 0.10)', md: 'transparent' }}
      borderRadius={{ base: '8px', md: '0' }}
      direction='column'
      gap='10px'
      p={{ base: '8px', md: '0' }}
    >
      <Flex align='center'>
        <SquareTeamCard id={team_1_id} {...{ position: 1, matchId: id }} />
        <SquareTeamCard id={team_2_id} {...{ position: 1, matchId: id }} />
      </Flex>
      <Box fontSize='10px' textAlign='center'>
        <Text>{t(tKeys.willWin, { team: selectedTeam?.abbreviation })}</Text>
      </Box>

      <Grid
        gap='10px'
        gridTemplateColumns='repeat(4, 1fr)'
        m='0 auto'
        w={'fit-content'}
      >
        {BONUS_GAMES_NUMBERS.map((value) => {
          const isActive =
            value ===
            Number(
              pathname === bracketsSecondChancePath()
                ? entrySC[key]
                : entry[key],
            );
          return (
            <AnswerButton
              isActive={isActive}
              key={value}
              onClick={() => clickHandler(value)}
            >
              {value}
            </AnswerButton>
          );
        })}
      </Grid>
    </Flex>
  );
};
