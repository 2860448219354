import React from 'react';

import { Grid, GridItem, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import cupImg from '@/assets/leagues/stanley-cup.svg';

import { useGameDate } from '@/hooks/useGameDate';

type Props = {
  isTablet: boolean;
};

export const BracketsListHeader: React.FC<Props> = (props) => {
  const { isTablet } = props;

  const { t } = useTranslation();
  const { isOver } = useGameDate();

  if (isTablet) return null;

  return (
    <Grid
      background='#18252f'
      gap={3}
      marginTop='3px'
      padding='8px 12px'
      templateColumns='repeat(14, 1fr)'
    >
      <GridItem alignItems='center' colSpan={3} display='flex'>
        <Text>{t(tKeys.name)}</Text>
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={2}
        display='flex'
        textAlign='center'
      >
        <Text>{t(tKeys.stanleyCupFinals)}</Text>
      </GridItem>

      <GridItem
        alignItems='center'
        colSpan={1}
        display='flex'
        justifyContent='center'
      >
        <Image src={cupImg} />
      </GridItem>

      <GridItem
        alignItems='center'
        colSpan={3}
        display='flex'
        justifyContent='center'
      >
        <Text>{isOver ? t(tKeys.details) : t(tKeys.edit)}</Text>
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={2}
        display='flex'
        justifyContent='center'
      >
        <Text>Pts</Text>
      </GridItem>
      <GridItem
        alignItems='center'
        colSpan={3}
        display='flex'
        justifyContent='center'
      >
        <Text>{t(tKeys.leaguesEntered)}</Text>
      </GridItem>
    </Grid>
  );
};
