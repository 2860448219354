import {
  FormatUserGeoPositionResType,
  GeyUserGeoPositionRes,
} from '@/types/domain';

export const formatUserGeoPositionRes = (
  data: GeyUserGeoPositionRes,
): FormatUserGeoPositionResType => {
  let country = '';
  let region = '';

  data.results.map((result) => {
    result.address_components.map((component: any) => {
      if (component.types.includes('country')) {
        country = component.short_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        region = component.short_name;
      }
    });
  });

  return { country, region };
};

export const availableCountriesPrePlay = [
  'CA', //Canada
  'US', //United States
];
