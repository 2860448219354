import React from 'react';

import {
  Grid,
  GridItem,
  Button,
  Text,
  useMediaQuery,
  Flex,
  Image,
  Spinner,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import hierarchyIcon from '@/assets/icons/hierarchy.svg';
import { ReactComponent as LockIcon } from '@/assets/icons/lock-filled.svg';
import previewIcon from '@/assets/icons/preview.svg';
import trophyIcon from '@/assets/icons/trophy.svg';
import userGroupIcon from '@/assets/icons/user-group.svg';

import { useGameDate } from '@/hooks/useGameDate';

import { LeagueEntryRender } from '@/modules/Leagues/containers/LeagueEntryRender';
import { LeagueIconKey } from '@/modules/Leagues/leagues.enums';
import { leagueIcons } from '@/modules/Leagues/leagues.mocks';
import { League } from '@/modules/Leagues/leagues.types';

type Props = {
  leagues: League[];
  entryId?: string;
  lastLeagueRef?: (node?: Element | null) => void;
  onNavigateToLeagueManage: (leagueId: string) => void;
  isFetching: boolean;
  offset?: number;
  isAssignLeague?: boolean;
};

export const LeaguesList: React.FC<Props> = (props) => {
  const {
    leagues,
    lastLeagueRef,
    isFetching,
    onNavigateToLeagueManage,
    offset,
    isAssignLeague,
  } = props;
  const { t } = useTranslation();

  // TODO global settings.
  const [isMobile] = useMediaQuery('(max-width: 900px)');
  const { isComing } = useGameDate();

  return (
    <>
      {leagues.map((league, index) => (
        <Grid
          background='rgba(18, 18, 18, 0.50)'
          gap={isMobile ? 4 : 1}
          key={index}
          marginBottom='4px'
          padding='12px 16px'
          ref={index === leagues.length - 1 ? lastLeagueRef : undefined}
          templateColumns={isMobile ? 'repeat(8, 1fr)' : 'repeat(10, 1fr)'}
          width='100%'
        >
          <GridItem
            colSpan={isMobile ? 8 : 3}
            width={isMobile ? '100%' : '250px'}
          >
            <Flex
              alignItems='center'
              cursor='pointer'
              h='100%'
              onClick={() => onNavigateToLeagueManage(league.league_id)}
            >
              <Image
                alt='league-image'
                fallbackSrc={leagueIcons[LeagueIconKey.NhlLeague]}
                marginRight='12px'
                src={
                  leagueIcons[league.icon as LeagueIconKey]
                    ? leagueIcons[league.icon as LeagueIconKey]
                    : league.icon
                }
                w='40px'
              />
              <Text
                _hover={{
                  textDecoration: 'underline',
                }}
                cursor='pointer'
                wordBreak='break-word'
              >
                {league.name}
              </Text>
            </Flex>
          </GridItem>
          <GridItem colSpan={isMobile ? 2 : 1} w='100%'>
            {/*TODO*/}
            <Flex
              alignItems='center'
              flexDirection='column'
              h='100%'
              justifyContent='center'
            >
              {isMobile && (
                <Image height='24px' src={previewIcon} width='24px' />
              )}
              <Button
                onClick={() => onNavigateToLeagueManage(league.league_id)}
                textDecoration='none'
                variant='link'
              >
                {t(tKeys.details)}
              </Button>
            </Flex>
          </GridItem>
          <GridItem colSpan={isMobile ? 2 : 1} w='100%'>
            <Flex
              alignItems='center'
              flexDirection='column'
              h='100%'
              justifyContent='center'
              textTransform='capitalize'
            >
              {isMobile && (
                <Image height='24px' src={hierarchyIcon} width='24px' />
              )}

              <Flex align='center' gap='4px'>
                {t(league.type.toLocaleLowerCase())}
                {league?.is_private && (
                  <Tooltip
                    arrowSize={16}
                    bg='#FFF'
                    hasArrow
                    label={t(tKeys.passwordRequired)}
                    offset={[0, 16]}
                  >
                    <LockIcon />
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          </GridItem>
          <GridItem colSpan={isMobile ? 2 : 1} w='100%'>
            {/*TODO backend returns boolean like string*/}
            <Flex
              alignItems='center'
              flexDirection='column'
              h='100%'
              justifyContent='center'
            >
              {isMobile && (
                <Image height='24px' src={trophyIcon} width='24px' />
              )}
              {league.prize ? t(tKeys.prize) : '--'}
            </Flex>
          </GridItem>
          <GridItem colSpan={isMobile ? 2 : 1} w='100%'>
            <Flex
              alignItems='center'
              flexDirection='column'
              h='100%'
              justifyContent='center'
            >
              {isMobile && (
                <Image height='24px' src={userGroupIcon} width='24px' />
              )}
              {isComing
                ? league.size
                : league.rank
                  ? `${league.rank}/${league.size}`
                  : `--/${league.size}`}
            </Flex>
          </GridItem>
          <GridItem
            colSpan={isMobile ? 8 : 3}
            width={isMobile ? '100%' : '250px'}
          >
            <Flex alignItems='center' h='100%' justify='center'>
              <LeagueEntryRender
                isAssignLeague={isAssignLeague}
                league={league}
                onNavigateToLeagueManage={onNavigateToLeagueManage}
              />
            </Flex>
          </GridItem>
        </Grid>
      ))}
      {Boolean(isFetching && offset) && (
        <Flex
          alignItems='center'
          background='rgba(18, 18, 18, 0.50)'
          justifyContent='center'
          padding='15px'
          width='100%'
        >
          <Spinner size='lg' />
        </Flex>
      )}
    </>
  );
};
