import React, { PropsWithChildren, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DEFAULT_LANGUAGE_KEY, enabledLanguages } from '@/i18n';

import { RoutesEnum } from '@/enums/routes.enum';

import { useLang } from '@/hooks/useLang';

import { createPath } from '@/routes/types';
import { addLangToRoute } from '@/routes/utils';

export const URLParamsWatcherOutlet: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const { pathname } = useLocation();
  const { lang: langLS, setLang: setLangLS } = useLang();
  const navigate = useNavigate();

  useEffect(() => {
    if (!lang) {
      const updatedPath = addLangToRoute(pathname, langLS || 'en');
      navigate(updatedPath, { replace: true });
    }
    //eslint-disable-next-line
  }, [lang, langLS, pathname]);

  useEffect(() => {
    if (lang) {
      const isValidLang = enabledLanguages.includes(
        lang as (typeof enabledLanguages)[0],
      );
      const langChecked = isValidLang ? lang : 'en';
      i18n.changeLanguage(langChecked, () => setLangLS(langChecked));

      if (!isValidLang) {
        navigate(
          `/${DEFAULT_LANGUAGE_KEY}/${createPath({ path: RoutesEnum.home })}`,
          {
            replace: true,
          },
        );
      }
    }
    //eslint-disable-next-line
  }, [lang]);

  return <>{children}</>;
};
