import { FC, useCallback, useState } from 'react';

import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { tKeys } from '@/i18n';
import { useAppDispatch, useAppSelector } from '@/store';

import { OfficialRules } from '@/components/OfficialRules';

import { ModalWindow } from '@/elements/ModalWindow';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useJWT } from '@/hooks/useJWT';
import { useToast } from '@/hooks/useToast';

import {
  useAddEntryToLeagueMutation,
  useLazyGetSecondChanceEntryQuery,
} from '@/modules/Bracket/bracket.api';
import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import { BracketMode } from '@/modules/Bracket/brackets.enums';

import { createPath } from '@/routes/types';

import { errorHandler } from '@/utils/api.util';

import {
  CHANGE_FINAL_BONUS_MODAL_STEP,
  selectBracket,
  SET_ENTRY_MATCH_PICK,
  SET_ENTRY_MATCH_PICK_SC,
  SET_ENTRY_SC,
  SET_INITIAL_ENTRY_SC,
} from '../../bracket.slice';
import { useEntry } from '../../hooks/useEntry';

import { StepOneContent } from './StepOneContent';
import { StepTwoContent } from './StepTwoContent';
// import { useSettings } from '@/hooks/useSettings';

export const FinalBonusModal: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { isLoading, submit } = useEntry();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { jwt } = useJWT();
  const {
    entry,
    entrySC,
    isReadOffRules,
    finalModal: { step },
    bracketMode,
  } = useAppSelector(selectBracket);
  const [showRules, setShowRules] = useState(false);

  const [addEntryToLeague, { isLoading: isLoadingAddEntry }] =
    useAddEntryToLeagueMutation();
  const [getSC, { isLoading: isLoadingGetSC }] =
    useLazyGetSecondChanceEntryQuery();

  const leagueIdSearchParam = searchParams.get(SearchParamsEnum.leagueId);

  const setStep = useCallback(
    (value: number) => dispatch(CHANGE_FINAL_BONUS_MODAL_STEP(value)),
    [dispatch],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const response = await submit();
      if (leagueIdSearchParam && response?.entry_id) {
        await addEntryToLeague({
          entryId: response?.entry_id,
          leagueId: leagueIdSearchParam,
        }).unwrap();
      }
      onClose();

      setTimeout(() => {
        const isMobile = window.innerWidth <= 768;

        if (isMobile) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          const bracketElement = document.getElementById('bracket-name');
          if (bracketElement) {
            bracketElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        }
      }, 300);

      if (pathname === bracketsSecondChancePath()) {
        const res = await getSC({ jwt }).unwrap();
        if (res.entry) {
          searchParams.set(SearchParamsEnum.viewType, BracketMode.edit);
          setSearchParams(searchParams);
          dispatch(SET_INITIAL_ENTRY_SC(res.entry));
          dispatch(SET_ENTRY_SC(res.entry));
        }
        return;
      }

      if (response?.entry_id) {
        return navigate(
          createPath({
            path: RoutesEnum.bracketsSpecific,
            params: { id: response?.entry_id },
          }),
        );
      }
    } catch (error) {
      errorHandler({ toast, t, error });
    }
  }, [
    addEntryToLeague,
    dispatch,
    getSC,
    leagueIdSearchParam,
    navigate,
    onClose,
    pathname,
    searchParams,
    setSearchParams,
    submit,
    t,
    toast,
  ]);

  const onNextClick = useCallback(async () => {
    if (step === 1) {
      return setStep(2);
    }

    if (step === 2) {
      return await handleSubmit();
    }
  }, [handleSubmit, setStep, step]);

  const onCancelClick = useCallback(() => {
    if (step === 1) {
      dispatch(
        pathname === bracketsSecondChancePath()
          ? SET_ENTRY_MATCH_PICK_SC({ matchId: 401, teamId: 0 })
          : SET_ENTRY_MATCH_PICK({ matchId: 401, teamId: 0 }),
      );
      onClose();
      return;
    }

    if (step === 2) {
      return setStep(1);
    }
  }, [dispatch, onClose, pathname, setStep, step]);

  const isFirstStepDisabled =
    step === 1 &&
    (pathname === bracketsSecondChancePath()
      ? !entrySC.match_401_pick
      : !entry.match_401_pick);
  const isSecondStepDisabled =
    step === 2 &&
    (!isReadOffRules ||
      (pathname === bracketsSecondChancePath()
        ? !entrySC.tiebreaker
        : !entry.tiebreaker));

  const isDisabled = isFirstStepDisabled || isSecondStepDisabled;

  const StepComponent = useCallback(
    () =>
      step === 1 ? (
        <StepOneContent />
      ) : (
        <StepTwoContent onRulesOpen={() => setShowRules(true)} />
      ),
    [step],
  );

  const StepFooterContent = useCallback(
    () => (
      <>
        {step === 2 && (
          <Button onClick={onCancelClick} variant='unstiled'>
            {t(tKeys.previous)}
          </Button>
        )}
        <Button
          colorScheme='green'
          isDisabled={isDisabled}
          isLoading={isLoading || isLoadingAddEntry}
          mr={3}
          onClick={onNextClick}
        >
          {t(tKeys.submitBracket, { step })}
        </Button>
      </>
    ),
    [
      isDisabled,
      isLoading,
      isLoadingAddEntry,
      onCancelClick,
      onNextClick,
      step,
      t,
    ],
  );

  return (
    <ModalWindow
      BodyContent={showRules ? <OfficialRules /> : <StepComponent />}
      FooterContent={
        showRules ? (
          <Button onClick={() => setShowRules(false)}>{t(tKeys.back)}</Button>
        ) : (
          <StepFooterContent />
        )
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};
