import { FC } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import { TeamType } from '../../bracket.types';

import { CompareLine } from './CompareLine';

export const StatisticRow: FC<{
  title: string;
  team1: TeamType;
  team2: TeamType;
  property: keyof TeamType;
}> = ({ title, property, team1, team2 }) => {
  const value1 = Number(team1[property]);
  const value2 = Number(team2[property]);

  const color1 = `#${team1.hex_code}`;
  const color2 = `#${team2.hex_code}`;

  const percent1 = (value1 / (value1 + value2)) * 100;
  const percent2 = (value2 / (value1 + value2)) * 100;

  return (
    <Box>
      <Text fontWeight='bold' textAlign='center'>
        {title}
      </Text>
      <Flex align='center' gap={'10px'} justify='space-between'>
        <Text
          fontSize='16px'
          fontWeight='800'
          minW={{ base: '40px', md: '60px' }}
          textAlign='right'
        >
          {Math.round(value1 * 100) / 100}
        </Text>
        <CompareLine {...{ color1, color2, percent1, percent2 }} />
        <Text
          fontSize='16px'
          fontWeight='800'
          minW={{ base: '40px', md: '60px' }}
          textAlign='left'
        >
          {Math.round(value2 * 100) / 100}
        </Text>
      </Flex>
    </Box>
  );
};
