import { useMemo } from 'react';

import { Flex, Grid, Text, Link, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  GAME_LINK_LINES,
  GAME_LINK_PEPSI,
  GAME_LINK_PRE_PLAY_CA,
  GAME_LINK_PRE_PLAY_US,
  GAME_LINK_STATS_STREAK,
  GAMEZONE_URL,
} from '@/core/env';
import { tKeys } from '@/i18n';

import GamezoneLogo from '@/assets/images/link-images/gamezone-logo.png';
import LinesLink from '@/assets/images/link-images/nhl-lines-link-en.webp';
import PepsiLink from '@/assets/images/link-images/nhl-pepsi-link-en.webp';
import PreplayLink from '@/assets/images/link-images/nhl-preplay-link-en.webp';
import StatsLink from '@/assets/images/link-images/nhl-stats-streak-link-en.webp';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { UserGeoPositionType } from '@/types/domain';

//* Local Types
type GameLinks = {
  url: string | undefined;
  name: string;
  imgUrl: string;
};

export const GameZoneLinks = () => {
  const { t } = useTranslation();

  const { value: geoLocation } = useLocalStorage<UserGeoPositionType>(
    StorageKeysEnum.geoPosition,
  );

  //* Local Data
  const gameLinks = useMemo<GameLinks[]>(() => {
    const { country } = geoLocation || '';

    const prePlayLink =
      country === 'CA' ? GAME_LINK_PRE_PLAY_CA : GAME_LINK_PRE_PLAY_US;
    const prePlayName =
      country === 'CA' ? 'NHL PrePlay Canada Link' : 'NHL PrePlay US Link';

    return [
      {
        name: 'NHL Lines Link',
        url: GAME_LINK_LINES,
        imgUrl: LinesLink,
      },
      {
        name: 'NHL StatsStreak Link',
        url: GAME_LINK_STATS_STREAK,
        imgUrl: StatsLink,
      },
      {
        name: prePlayName,
        url: prePlayLink,
        imgUrl: PreplayLink,
      },
      {
        name: 'NHL Goalie Challenge Link',
        url: GAME_LINK_PEPSI,
        imgUrl: PepsiLink,
      },
    ];
  }, [geoLocation]);

  return (
    <Flex align='center' direction='column' gap='20px' py='40px'>
      {/* Gamezone Logo */}
      <Link href={GAMEZONE_URL} target='_blank'>
        <img
          alt='logo'
          src={GamezoneLogo}
          style={{ width: '100%', maxWidth: '260px' }}
        />
      </Link>

      {/* Copy */}
      <Text
        fontSize={{ base: '18px', md: '24px' }}
        fontWeight='bold'
        maxW='600px'
        textAlign='center'
      >
        {t(tKeys.ctaText)}
      </Text>

      {/* Games Links */}
      <Grid
        gap={3}
        templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
      >
        {gameLinks.map((gameLink, idx) => (
          <Link
            href={gameLink.url}
            isExternal
            key={gameLink.name}
            onClick={() => {
              const { dataLayer } = window ?? [];
              dataLayer.push({
                event: 'internal_link_click',
                click_text: 'Play',
                click_section: 'Other GameZone Games',
                click_url: gameLink.url,
                index: idx,
              });
            }}
            target='_blank'
          >
            <Image
              _hover={{ transform: 'scale(1.05)' }}
              alt={gameLink.name}
              maxW={{ base: '36', md: '56' }}
              src={gameLink.imgUrl}
              transition='all ease-in'
            />
          </Link>
        ))}
      </Grid>
    </Flex>
  );
};
