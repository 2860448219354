import React from 'react';

import { Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { tKeys } from '@/i18n';

import NhlImage from '@/assets/icons/nhl-shield.svg';

import { JoinLeagueAction } from '@/modules/LeagueManage/components/JoinLeagueAction';
import { DeleteLeague } from '@/modules/LeagueManage/containers/DeleteLeague';
import {
  getIsManageMode,
  getLeagueData,
} from '@/modules/LeagueManage/leagueManage.selectors';
import { RevealPasswordButton } from './RevealPassword';

type Props = {
  leagueImageSrc: string | undefined;
  leagueName: string | undefined;
  leagueSize: string | undefined;
};
export const LeagueInfoMobile: React.FC<Props> = ({
  leagueSize,
  leagueName,
  leagueImageSrc,
}) => {
  const { t } = useTranslation();
  const isManageMode = useSelector(getIsManageMode);

  return (
    <Flex
      alignItems='center'
      bgColor='primaryBlue'
      flexDirection='column'
      gap='16px'
      justifyContent='center'
      mt='8px'
      p='16px'
    >
      <Image fallbackSrc={NhlImage} src={leagueImageSrc} width='100px' />
      <Text fontSize='24px'>{leagueName}</Text>
      {isManageMode && (
        <Flex alignItems='center' justifyContent='space-between'>
          <DeleteLeague />
        </Flex>
      )}
      {isManageMode && <RevealPasswordButton />}

      <Text fontSize='14px' fontWeight='600' textTransform='uppercase'>
        {t(tKeys.members)}:
      </Text>
      <Text fontFamily='Antonio' fontSize='30px' lineHeight='100%'>
        {leagueSize}
      </Text>
      <JoinLeagueAction />
    </Flex>
  );
};

export default LeagueInfoMobile;
