import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Heading,
  useDisclosure,
  Box,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import copyIcon from '@/assets/icons/copy-icon.svg';
import { useSelector } from 'react-redux';
import { getLeagueData } from '../leagueManage.selectors';
import AdsBanner from '@/components/AdsBanner';
import { useSettings } from '@/hooks/useSettings';

export const RevealPasswordButton: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const leagueData = useSelector(getLeagueData);
  const toast = useToast();
  const { isMobile } = useSettings();

  const { onCopy } = useClipboard(leagueData?.passcode || '');

  const handleCopy = () => {
    onCopy();
    toast({
      title: t('successfullyCopy'),
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top',
    });
  };

  if (!leagueData?.is_owner) return null;

  return (
    <>
      <Button
        alignItems='center'
        borderRadius='60px'
        height={isMobile ? '46px' : '36px'}
        paddingBottom={isMobile ? 4 : 2}
        paddingLeft={isMobile ? 8 : 6}
        paddingRight={isMobile ? 8 : 6}
        paddingTop={isMobile ? 4 : 2}
        py='8px'
        size='14px'
        variant='success'
        width={isMobile ? '150px' : '146px'}
        onClick={onOpen}
      >
        {t('revealPassword')}
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius='0'>
          <ModalHeader flexDirection='column' justifyContent='center'>
            <AdsBanner mb='24px' slotId='modal-ad' slotSize={[226, 81]} />
          </ModalHeader>

          <ModalBody textAlign='center'>
            <Heading fontSize='20px' textAlign='center'>
              {t('leaguePassword')}
            </Heading>
          </ModalBody>
          <Box justifyContent='center' display='flex'>
            <Box
              bg={'inputBg'}
              border={'none'}
              borderRadius='50px'
              boxShadow={'0px 4px 10px 0px #00000073 inset'}
              h='54px'
              w='100%'
              maxWidth='362px'
              outline={'none'}
              textAlign={'start'}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Box fontSize='20px' fontWeight='400' paddingLeft='20px'>
                {leagueData?.passcode}
              </Box>

              <Button
                onClick={handleCopy}
                variant='link'
                p='18px'
                display='inline-flex'
                alignItems='center'
                justifyContent='center'
                cursor='pointer'
              >
                <Image
                  src={copyIcon}
                  alt='Copy Icon'
                  position='absolute'
                  right='5'
                />
              </Button>
            </Box>
          </Box>
          <ModalFooter flexDirection='column' marginTop='10px'>
            <Button marginTop='20px' onClick={onClose} variant='link'>
              {t('goBack')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
