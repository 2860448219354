import { TFunction } from 'i18next';
import * as yup from 'yup';

import { tKeys } from '@/i18n';

import { checkOnProfanity } from '@/utils/checkOnProfanity';
import { checkOnProhibitedPhrases } from '@/utils/checkOnProhibitedPhrases';
import { checkOnUniqeLeagueName } from '@/utils/checkOnUniqeLeagueName';

export const createLeagueValidationSchema = (t: TFunction) => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(30, 'Max 30 characters')
      .required('League Name is a required field')
      .matches(/^[\w\s]+$/, {
        message: 'Please use only letters and numbers',
      })
      .test(
        'profanity-word',
        t(tKeys.invalidLeagueName),
        (value) => !checkOnProfanity(value),
      )
      .test(
        'prohibited-phrase',
        'This phrase is prohibited',
        (value) => !checkOnProhibitedPhrases(value),
      )
      .test('name-exists', t(tKeys.leagueNameTaken), checkOnUniqeLeagueName),
    is_private: yup.boolean(),
    passcode: yup.string().when('is_private', {
      is: (value: string) => JSON.parse(value),
      then: () =>
        yup.string().trim().required(t(tKeys.passwordIsRequiredField)),
      otherwise: () => yup.string().trim(),
    }),
    icon: yup.string(),
  });
};
