import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { RightArrowButton } from '@/components/RightArrowButton';
import { useTranslation } from 'react-i18next';
import { tKeys } from '@/i18n';
import { useGameDate } from '@/hooks/useGameDate';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '@/enums/routes.enum';
import { createPath } from '@/routes/types';
import { useSettings } from '@/hooks/useSettings';

export const LeaderboardBanner: FC = () => {
  const { t } = useTranslation();
  const { isOver } = useGameDate();
  const navigate = useNavigate();
  const { isMobile } = useSettings();

  const handleNavigateToLeagues = () => {
    navigate(createPath({ path: RoutesEnum.leagues }));
  };

  if (!isOver) {
    return null;
  }

  return (
    <Flex
      align='center'
      bg='primaryDarkGradient'
      direction='column'
      h='213px'
      justify='space-between'
      my='24px'
      pt='40px'
      pb='40px'
      w='100%'
      px={isMobile ? '16px' : '0'}
    >
      <Box>
        <Box textAlign='center'>
          <Text fontSize='24px' fontWeight='700' lineHeight='1.3' mb='15px'>
            {t(tKeys.lookingForLeague)}
          </Text>
          <Text fontSize='16px' fontWeight='400' mb='24px'>
            {t(tKeys.checkOutLeaderboard)}
          </Text>
          <RightArrowButton onClick={handleNavigateToLeagues}>
            {t(tKeys.goToMyLeagues)}
          </RightArrowButton>
        </Box>
      </Box>
    </Flex>
  );
};
