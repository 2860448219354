import { ChakraProvider } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import '@/index.css';

import { setupStore } from '@/store';
import theme from '@/theme';

import { AuthContextProvider } from '@/contexts/auth.context';
import { LocalStorageProvider } from '@/contexts/localStorage.context';

import { GeoPositionProvider } from '@/providers/GeoPosition.provider';
import { MetaProvider } from '@/providers/Meta.provider';

import App from './App';
import Fonts from './Fonts';
import reportWebVitals from './reportWebVitals';

const store = setupStore();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <LocalStorageProvider>
        <Fonts />
        <BrowserRouter>
          <MetaProvider>
            <AuthContextProvider>
              <GeoPositionProvider>
                <App />
              </GeoPositionProvider>
            </AuthContextProvider>
          </MetaProvider>
        </BrowserRouter>
      </LocalStorageProvider>
    </ChakraProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
