import { FC, useState } from 'react';

import {
  Button,
  ButtonProps,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';
import { useAppDispatch, useAppSelector } from '@/store';

import { ModalWindow } from '@/elements/ModalWindow';
import { PageLoader } from '@/elements/PageLoader';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import {
  IBracketEntry,
  SecondChanceEntryType,
  SeriesResultsType,
} from '@/modules/Bracket/bracket.types';
import { BracketMode } from '@/modules/Bracket/brackets.enums';

import { internalLinkClickEvent } from '@/utils/analyticEvents';

import { useLazyCheckEntryNameQuery } from '../../../bracket.api';
import {
  CHANGE_BRACKET_NAME,
  CHECK_IS_FINAL_FILLED,
  CHECK_IS_FINAL_FILLED_SC,
  RESET_FINAL_MODAL_STATE,
  selectBracket,
  SET_EAST_BONUS_QUESTION_ANSWERED,
  SET_ENTRY,
  SET_ENTRY_SC,
  SET_WEST_BONUS_QUESTION_ANSWERED,
  UPDATE_SERIES_RESULT,
} from '../../../bracket.slice';
import {
  randomEntry,
  randomEntrySC,
  randomPick,
} from '../../../helpers/randomPick';

export const RandomPicksButton: FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const {
    entry,
    entrySC,
    game: { series_results },
    bracketMode,
  } = useAppSelector(selectBracket);

  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'random-pick-button',
  });

  const [loading, setLoading] = useState<boolean>(false);

  const [checkName] = useLazyCheckEntryNameQuery();

  const updateBracketName = async (): Promise<string> => {
    function getRandomString(): string {
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let string = '';
      for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        string += characters.charAt(randomIndex);
      }
      return string;
    }

    const name = `Bracket ${getRandomString()}`;

    const { exists } = await checkName({
      entry_name: name,
    }).unwrap();

    if (exists) {
      return updateBracketName();
    }

    return name;
  };

  const makeRandomPicks = async () => {
    internalLinkClickEvent('random-picks', window.location.href);

    setLoading(true);
    const filledSeriesResults = randomPick(
      series_results,
      pathname === bracketsSecondChancePath(),
    );

    // Update bracket UI
    dispatch(UPDATE_SERIES_RESULT(filledSeriesResults));
    // Update entry
    const filledEntry =
      pathname === bracketsSecondChancePath()
        ? randomEntrySC(filledSeriesResults, entrySC)
        : randomEntry(filledSeriesResults, entry);
    dispatch(
      pathname === bracketsSecondChancePath()
        ? SET_ENTRY_SC(filledEntry as SecondChanceEntryType)
        : SET_ENTRY(filledEntry as IBracketEntry),
    );
    dispatch(SET_WEST_BONUS_QUESTION_ANSWERED(true));
    dispatch(SET_EAST_BONUS_QUESTION_ANSWERED(true));

    // Update bracketName
    // const randomBracketName = await updateBracketName();
    // dispatch(CHANGE_BRACKET_NAME(randomBracketName));

    setLoading(false);

    dispatch(RESET_FINAL_MODAL_STATE());

    //   Close modal.
    onClose();

    const timeoutId = setTimeout(
      () =>
        dispatch(
          pathname === bracketsSecondChancePath()
            ? CHECK_IS_FINAL_FILLED_SC(filledEntry as SecondChanceEntryType)
            : CHECK_IS_FINAL_FILLED(filledEntry as IBracketEntry),
        ),
      800,
    );
    return () => clearTimeout(timeoutId);
  };

  return (
    <>
      {loading && <PageLoader />}

      <Button
        gap={'5px'}
        onClick={onOpen}
        variant='outline'
        w={'fit-content'}
        {...props}
      >
        {t(tKeys.randomPicks)}
        <svg
          fill='none'
          height='24'
          viewBox='0 0 24 24'
          width='24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M16.3038 15.4495C15.8378 15.3023 15.2431 14.9193 14.5766 14.2517C14.006 13.6927 13.4163 12.9716 12.8117 12.185C12.6685 12.3781 12.5245 12.5697 12.3798 12.7659C11.9855 13.3006 11.5815 13.8401 11.1706 14.3703C12.6051 16.1143 14.0955 17.8234 16.3038 18.1056V20.1429L22 16.8526L16.3038 13.5632V15.4495ZM6.80977 8.87075C7.37969 9.42975 7.96917 10.1509 8.57313 10.9368C8.7202 10.7392 8.8671 10.5439 9.01567 10.3425C9.40842 9.81228 9.80792 9.27738 10.2158 8.75088C8.68466 6.89294 7.10003 5.04944 4.64018 4.96039H2.00164V7.60043H4.64018C5.13471 7.5809 5.90814 7.96714 6.80972 8.87079L6.80977 8.87075ZM16.3038 7.66978V9.58194L22 6.29097L16.3038 3V4.99669C14.8684 5.16185 13.7217 6.01348 12.7222 6.99104C11.0667 8.63838 9.61924 10.8885 8.19081 12.6767C6.78336 14.5156 5.37052 15.6154 4.63854 15.5211H2V18.1612H4.63854C6.28576 18.1393 7.56282 17.2062 8.66415 16.1298C10.3181 14.4824 11.7671 12.2331 13.1943 10.4441C14.3582 8.92342 15.52 7.92482 16.304 7.66974L16.3038 7.66978Z'
            fill='white'
          />
        </svg>
      </Button>

      <ModalWindow
        BodyContent={
          <>
            <Heading mt={'15px'} pb='16px' size='sm' textAlign='center'>
              {t(tKeys.areYouSureRandomPicks)}
            </Heading>
            <Text textAlign='center'>
              {t(tKeys.yourPicksWillBeOverwritten)}
            </Text>

            <Button mt={'30px'} onClick={makeRandomPicks}>
              {t(tKeys.activateRandomPicks)}
            </Button>
            <Button mt={'15px'} onClick={onClose} variant='ghost'>
              {t(tKeys.cancel)}
            </Button>
          </>
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
