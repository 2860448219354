import React, { FC } from 'react';

import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import AdsBanner from '@/components/AdsBanner';

import { ModalWindow } from '@/elements/ModalWindow';

import { internalLinkClickEvent } from '@/utils/analyticEvents';

import { AnalysisContent } from './AnalysisContent';
import search from './search.svg';

export const AnalysisButton: FC<{ team_1_id: number; team_2_id: number }> = ({
  team_1_id,
  team_2_id,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: `analysis-${team_1_id}-${team_2_id}`,
  });
  const { t } = useTranslation();

  const handleOpenModal = () => {
    internalLinkClickEvent('analysis', window.location.href);
    onOpen();
  };

  return (
    <>
      {/* Button */}
      <Flex
        align='center'
        as='button'
        bgColor='white'
        fontFamily='AvertaStd-Bold, sans-serif'
        fontWeight='bold'
        gap={'5px'}
        h='25px'
        justify='center'
        onClick={handleOpenModal}
        p={'5px 20px'}
        w='100%'
      >
        <Image h={'100%'} src={search} />
        <Text
          color='#071520'
          fontSize='9px'
          textTransform={'uppercase'}
          whiteSpace='nowrap'
        >
          {t(tKeys.analysis)}
        </Text>
      </Flex>

      {/*  Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size={'700px'}>
        <ModalOverlay />
        <ModalContent
          bg={'linear-gradient(141.41deg, #153449 1.32%, #3D617B 98.18%)'}
          borderRadius='4px'
          boxShadow='0px 4px 4px 0px #0000008C'
          color='white'
          maxH={['98vh', 'auto']}
          maxW={'700px'}
          my='0'
          p='0'
          width='100%'
        >
          <ModalHeader px='0'>
            <Box h='81px' m={'0 auto'}>
              <AdsBanner slotId='modal-ad' slotSize={[226, 81]} />
            </Box>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody overflowY='auto' p='0'>
            <AnalysisContent
              {...{
                team_1_id,
                team_2_id,
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
