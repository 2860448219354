import { Box, Image } from '@chakra-ui/react';

import AdsBanner from '@/components/AdsBanner';
import { Footer } from '@/components/Layout/Footer';

import { useAuthContext } from '@/hooks/useAuthContext';
import { useGameDate } from '@/hooks/useGameDate';
import { useSettings } from '@/hooks/useSettings';

import { GameZoneLinks } from '@/modules/Main/components/GameZoneLinks';
import { Guide } from '@/modules/Main/components/Guide';
import { Leagues } from '@/modules/Main/containers/Leagues';
import { Preregistration } from '@/modules/Main/containers/Preregistration';

import { hiddenScrollbarStyles } from '@/utils/common.util';

export const Main = () => {
  const { isLgDesktop, isMobile, isDesktop } = useSettings();
  const { isLive, isSecondChanceLive } = useGameDate();
  const { onSignInOpen } = useAuthContext();

  const handlePreregister = () => {
    onSignInOpen();
  };

  return (
    <>
      <Box
        height={isLgDesktop ? '650px' : '100%'}
        maxW={isLgDesktop ? '1000px' : '100%'}
        mt={isMobile ? '87px' : 0}
        overflow={isLgDesktop ? 'auto' : 'unset'}
        sx={hiddenScrollbarStyles}
        w='100%'
      >
        <Preregistration onPreregister={handlePreregister} />
        {isLive && <Guide my='40px' />}
        <Leagues />

        <GameZoneLinks />

        <AdsBanner
          my='30px'
          slotId='mobile-bottom-banner-ad'
          slotSize={isDesktop ? [728, 90] : [320, 50]}
        />
        <Footer />
      </Box>
    </>
  );
};
