const PROHIBITED_PHRASES = [
  'i like something',
  'long, hard and durable',
  'riot',
  'trigger',
  'triggered',
  'white privilege ',
  'politics',
  'i need something ',
  'ᵒᵒᵒᵒᵒᵐᵍ ⁱ ʷᵃⁿᵗ ᵃ ᵍᵉⁿᵗᵉˡᵐᵃⁿ',
  'follow for ',
  'ˡⁱᵏᵉ ⁱᶠ ʸᵒᵘ ᵃʳᵉ ᵐᵒʳᵉ ¹⁶ ᶜᵐ',
  'i really like cucumber.! do you like him too? 😝😜',
  'free link-images bets',
  '@christensen_james_fxt',
  'do not tap on my username',
  'who can impregnate me. because my man is weak',
  'warning!!!!!! do not view my story!!',
  'sim league',
  '@russell_stock_expert',
  'ⁱ ᵃᵐ ᵗʰⁱʳˢᵗʸ, ʷᵃⁱᵗⁱⁿᵍ ᶠᵒʳ ʸᵒᵘ',
  "i am everyone's dream girl !!!",
  'i think dad is always stimulating🤭',
  '@tastrades',
  'feels kinda sad today😔',
  '@trade_with_orsborne',
  '@earn_with_dylanfx',
  '@earn_with_emily_fxt',
  '@earn_profits_with_john_eric',
  'new page, give us a look!!',
  '@jennifer_expert_trader9',
  'guess what size is my bra? ',
  'i’m online❤',
  '𝑪𝒂𝒓𝒕𝒐𝒐𝒏 𝒈𝒓𝒂𝒑𝒉𝒊𝒄𝒔',
  '𝐛𝐢𝐧𝐚𝐫𝐲/𝐟𝐨𝐫𝐞𝐱 𝐭𝐫𝐚𝐝𝐢𝐧𝐠',
  'live now 🔴►',
  '@a.bwagers',
  '@elizabet.morrison01',
  '@trade_with_olivia_lindsay',
  'kys',
  'snow flake',
  'dipshit',
  'kill yourself',
  'sexi',
  'spiÃ§',
  'sodomy',
  'straight people pride',
  'sodomite',
  'original memes',
  'dm for ',
  'dm for',
  'bye link-images',
  'amerikkka',
  'mob',
  'nut jobs',
  'grow balls',
  'darkies',
  'pedo',
  'nut job',
  'grew balls',
  'had balls',
  'klan',
  'done with link-images ',
  'felon',
  'not watching link-images',
  'pansy',
  'no more link-images',
  'asshat',
  'dbag',
  'domestic terrorist',
  'gtfo',
  'domestic terrorists',
  'cult',
  'pussys',
  'jackass',
  'domestic terrorism',
  'kkk',
  "you're a jew",
  'fkn',
  'trump 2020ðŸ‡ºðŸ‡¸',
  'ass hat',
  'fuk',
  'blm is racist',
  'all lives matter',
  'dumbass',
  'dumbasses',
  'pussies',
  'dumbfuckistan',
  'fuking',
  'd-bag',
  'fake agenda',
  'politics out of hockey',
  'rate me',
  'watch my ',
  'private video',
  'coke head',
  'private videos',
  'bills covered',
  'live streams',
  'link here',
  '@micheal_richardsfx',
  '@crypto_wilson_trader_fx',
  'scammed',
  '@investor_christopher_crypto',
  'watch live====>>>',
  'fan page',
  '@karenlisa_forex_trade',
  '@alice_klein_cryptos',
  'rate my foto',
  'who needs his/her bills paid',
  '#weskateformarxism',
  'forex trading',
  '@zarina_fx',
  'live stream',
  'dm',
  'copy and paste',
  '@educator_nancyfx',
  'scammed,',
  'link here: ',
  'on my page',
  'follow my tiktok ',
  '#birthcertificatefraud',
  'i invested',
  'look at my story',
  'link hd',
  'live link:>>>',
  'sub to me',
  '@marvntrades',
  'black lives donâ€™t matter',
  'mike weirsky,',
  'trust worthy account manager',
  'testimony',
  'candid pics',
  'mr gary',
  '@pamela_binary_expert_ ',
  'end my order',
  'share everywhere',
  '@earn_with_lucy_fx',
  'forex',
  '#worldwidewarning',
  'live link',
  'rate my new photos',
  'get me a profit',
  ' make all sort of artwork',
  'i sell',
  'financial support',
  'follow here',
  'hi.rate me in my profile',
  '@officialconsultant_debbie',
  'watch !!!',
  'rate me in my profile',
  '@gary___tomson_',
  'send me message',
  'pay my bills',
  'follow to join',
  'waiting in profile',
  '@jody_steiner_fx1',
  'dont look at my story',
  'pay my bill',
  'send a message',
  'contact me',
  'live here',
  'sportsurge.net',
  'link here:',
  'fix your page',
  '.rate me in my profile',
  'rate my new photos,',
  'rate my new photo',
  'please watch and share',
  'send my order',
  'link in our bio',
  'watch my video',
  'i make all sort of artwork',
  'hoax',
  'live on',
  'www.shazerwan.com',
  'covid cup',
  'https://is.gd/banmvk',
  'watch live',
  'binary ',
  'debt free',
  'in my story',
  'live links',
  'binary trading',
  '@theclaire_financial_freedom',
  'criminal',
  'martyr',
  'political gain',
  'rig ',
  'politics out',
  '🏳️‍🌈⃠',
  'get woke go broke',
  'caved',
  'destroy america',
  'go broke',
  '𝖽𝖺𝗂𝗅𝗒 𝗍𝗋𝖺𝖽𝗂𝗇𝗀 𝗌𝗂𝗀𝗇𝖺𝗅𝗌',
  'rigged',
  'see my bio',
  '𝐰𝐢𝐭𝐡𝐝𝐫𝐚𝐰𝐚𝐥',
  '𝐦𝐚𝐝𝐞 𝐩𝐫𝐨𝐟𝐢𝐭𝐬',
  '@dr_isespell💜',
  'paid to play',
  '𝒆 𝒛 𝒃 𝒊 𝒈 𝒑 𝒂 𝒚 .𝒄 𝒐 𝒎',
  '✅𝕃𝕀𝕍𝔼✅👉',
  '【🔴watch】',
  'would you help me take off my clothes🥰',
  'hate group',
  'oppressor',
  '🆚',
  '🔴👉',
  'radical leftist',
  'sports without politics',
  'support criminals',
  'oppression',
  'join me',
  'constatly bother me',
  'just play hockey',
  'hate blacks',
  'oppressed',
  'david scott',
  "i'm bored",
  'im bored',
  'cowards',
  'sheep',
  'take a knee',
  'entertainment in my profile',
  'telegram',
  'looting',
  'stick to hockey',
  'no one cares',
  'rioting',
  'chinese sweatshops',
  'gunpoint',
  'fentanyl',
  'china',
  'are there any people interested?',
  'kaepernick',
  'false movement',
  'shitstains',
  'shut up and play',
  'daddy, l can sh0w you everything y0u want)',
  'i just took a shower',
  'do your job',
  'not your platform',
  'underwear',
  'vote on my story',
  'stick to your job',
  'thugs',
  'thug',
  'criminals',
  '𝗜 𝘄𝗮𝗻𝘁 𝘀𝗼𝗺𝗲𝘁𝗵𝗶𝗻𝗴 𝗹𝗼𝗻𝗴 𝗮𝗻𝗱 𝘁𝗵𝗿𝗶𝗰𝗸, 𝗱𝗼 𝘆𝗼𝘂 𝗵𝗮𝘃𝗲 𝗶𝘁',
  'hypocrite',
  'hate crime',
  'drug addicts',
  'piss off',
  'farce',
  'kaep',
  'they say my beats hit hard',
  'dumbo',
  '@lina.colby__',
  'send me a friend request',
  'fk',
  'white supremacy',
  'page in my bio',
  's*attle',
  'shattle',
  'dont care',
  'gayer',
  '@scott_stepfx2',
  'rate my foto ',
  '@harrybrandon_fxt',
  '𝓘 𝒽𝒶𝓋𝓮 𝒶 𝒷𝒾𝓇𝓉𝒽𝒹𝒶𝓎 𝓉𝓸𝒹𝒶𝓎 𝓅𝓁𝓮𝒶𝓈𝓮 𝓁𝒾𝓀𝓮 𝓂𝓎 𝓅𝒽𝓸𝓉𝓸',
  '𝗰𝗵𝗲𝗰𝗸 𝗼𝘂𝘁',
  '𝓥𝓲𝓼𝓲𝓽 𝓶𝓮',
  '@isabellarobert_',
  'sending a request',
  '@mary___anderson5',
  'p a i d t o b e h o m e .c o m',
  '@floral_expert_miner1',
  "i'm a cartoonist",
  "𝓹𝓵𝓪𝔂 𝔀𝓲𝓽𝓱 𝓶𝓮 𝓲'𝓶 𝓪 𝓫𝓲𝓽 𝓫𝓸𝓻𝓮𝓭 𝓬𝓱𝓮𝓬𝓴 𝓹𝓻𝓸𝓯𝓲𝓵𝓮 𝓰𝓾𝔂𝓼",
  '@suzanne_crypto_miner',
  '𝑅𝑎𝑡𝑒 𝑚𝑦',
  'boycott ea sports',
  'publicity',
  "i'm alone and lonely",
  '#boycottea',
  '𝕎𝕖 𝕤𝕖𝕝𝕝',
  '#boycotteasports',
  '@official_consultant.debbie',
  "don't look at my story",
  "trump's",
  'trump2020',
  'far right',
  'impeach',
  'coup',
  'far left',
  'impeachment',
  'qanon',
  "don't not view my story",
  'radicals',
  '@ariellefx',
  'the big one is delicious but the long lasting one is more delicious',
  'i need something big, long, hard and durable, where do i go?',
  '@earn_with_mariselvaleryfx',
  "i'm looking for a discreet relationship",
  'vachina',
  'storming the capitol',
  '@educator_lewisfx',
  '@earn_with_dianne',
  'wattch my squirtiing videos i just upload and preparre a tisue to cleans your speerm latter ',
  'storming the capital',
  'militant left',
  'trumptard',
  "because he's black",
  '@alice_klein_profit',
  'paying off the debts',
  'long cucumber',
  'why does the camera work poorly on iphone 11? ',
  'i go crazy remembering your longer!',
  'does anyone have',
  'like long and tough😍',
  'i need something warm and delicious🥰',
  'does anyone have something long and hard🤤🤫😝',
  'spit',
  'spits',
  'spitting',
  'i post secret dark web leaks ',
  'real man, show your charm ❤️',
  'ⅾ𝜊𝘦𝗌 𝗌𝜊𝓂𝘦𝜊𝜼𝘦 𝜔a𝜼𝘁 𝘁𝜊 𝜔𝘳і𝘁𝘦 𝓂𝘦?',
  '@thereal_lillian',
  '@bradley_daniella_fx',
  'she said no',
  'consensual',
  'consented',
  'concented',
  'sandwich',
  'sandwiches',
  'bots',
  'loot',
  'looted',
  'fraud',
  '@brad_neeld ',
  '@earn_with_roselyncryptotrade',
  'feeling sad today😔😔',
  '@tradewith_loncross',
  'today i had a very hot dream😈',
  'your daily reminder to drink water',
  'i need 🧡a long hard😜',
  '@paid_with__elizabeth_',
  'ᴺᵉʷ ʸᵉᵃʳ ⁿᵉʷ ᵇᵒʸᶠʳⁱᵉⁿᵈ',
  'i like men who are diligent in sports because they must be strong when making babies together 🤭',
  'cute sweet girl looking',
  '@invest_with_dianafx',
  '@md__nelson',
  'play with my😋',
  '🔰🔴𝐖𝐀𝐓𝐂𝐇 𝐋𝐈𝐕𝐄🔴#a_life_in_ruins',
  '@cherrymac_fx1',
  'make hockey violent again',
  '#recallbettman',
  'big pharma',
  'leftist',
  'rightist',
  'i really like 17.5 centimeters, what length do you have ?????🤩🤩🤩🥒',
  'i want to join you ',
  'you look superr cute!!🌟😘',
  '@mr_alanprenuer',
  '@jay_albert232',
  'big and long ',
  'hit me up',
  'i need somebody tonight 💞',
  'live 🔴hd live🔴►►',
  'i need a boyfriend',
  'breakingthebook.com',
  'long and big',
  'you are so nice',
  'opinion on my video production',
  'help me get',
  'my boyfriend left me ',
  'i post',
  '@victordariofx',
  'bang this evening ',
  'big, long and durable',
  'love this ????????????',
  'adipex😝',
  'что делать если много денег',
  'he nailed me all night long😛💝',
  '@loren___pagan',
  '𝓫𝓮𝓼𝓽 𝓹𝓾𝓫𝓵𝓲𝓬𝓪𝓽𝓲𝓸𝓷 𝓯𝓸𝓻 𝓽𝓸𝓭𝓪𝔂',
  'i am wet from morning to night😊',
  'my boyfriеnd left mе😢',
  'i slowly take off my drеss😍',
  'ｉ ｄｏｎｔ ｐｏｓｔ ｎａｋｅｄ ｇｉｒｌｓ ｏｒ ａｄｓ， ｊｕｓｔ ｍｅｍｅｓ．．',
  '@earnwith_lawson',
  'my body burns because of you🧡💝',
  'i literally steal memes off private accounts and post them publicly so you don’t have to follow! 💯',
  'feeling so lonely😢',
  '@olivia_jaden_fx',
  '#savesheikhjarrah',
  '#savepalestine',
  'straightphobia',
  'ridiculous acronym',
  'ha gayyyyy',
  'relax your throat',
  'staitphobia',
  'talk about go soft',
  'pick one already',
  '🐑🐑🐑',
  'sheeps',
  'experiemental jab',
  '@louis_elizabeth_f',
  'the jab',
  'nah he tweakin',
  "don't see my story",
  'lооking fоr а friеnd',
  'haha???',
  'something long and hard',
  'i need а bоуfriend',
  'make love to me',
  'interested in me',
  'stay at home job',
  '#boycottnhl',
  'groomer',
  'sus',
  'strait day',
  'strait month',
  'straight day',
  'straight month',
  's0domy',
  'straight white',
  'strait white',
  '📉',
  '👉👈',
  '𝐈 𝐢𝐧𝐯𝐞𝐬𝐭𝐞𝐝',
  'fire chris lee',
  'chris lee',
  '#firelee',
  '#firechrislee',
  'corrupt',
  '@fx_with_melinda_',
  '𝗜𝗠 𝗣𝗔𝗬𝗜𝗡𝗚 𝗢𝗙𝗙 𝗗𝗘𝗕𝗧𝗦',
  '@stephanie_expert_miner',
  'rigging',
  '@tradewithkatee_',
  '#fixyourref',
  '#firetherefs',
  '#firechrisslee',
  'cris lee',
  'mental disorder',
  '@official_michelle_lorenfx',
  '@fx_educator_creytens_robbie',
  "shhh, i'm getting bored today",
  '@carolyn__fxtrade',
  'i love to see you today',
  'taliban',
  'afghanistan',
  'who cares',
  'shower',
  'message me',
  '@james_fx_expert_trade_',
  '@ceo_scott_wallace',
  '@educator_florencehendricks',
  '@samuel.trades',
  '@robertmarkfx9',
  '@moonskid',
  '@earn_with_jayne_farell',
  'white history month',
  'communism',
  'communist',
  'biggots',
  'bigot',
  'bogot',
  'felony',
  'drug words',
  'gangster',
  'gang',
  'drug',
  'overdose',
  'drugs',
  'pedos',
  'moan',
  'antivax',
  'antivaxxer',
  'skin',
  '@esme_park_fx',
  '@nicole_andrew.fx',
  'russia',
  'russian',
  'russians',
  'ukraine',
  'ukrainian',
  'ukrainians',
  'sanction ovi',
  '#sanctionovi',
  "putin's",
  "russian's",
  '#putinpuppet',
  '🇷🇺',
  'privetik',
  'these comments will be nice i’m sure',
  'homophobes',
  '@isabella_forex937',
  "don't judge me after you see my stories",
  '"l"',
  'on mute',
  'down our throat',
  'down our throats',
  'down my throat',
  'hockey is not for everyone',
  'fruity',
  'two genders',
  'suss',
  'sussy',
  '#hockeyisntforeveryone',
  'wokeness',
  'xx',
  'xy',
  'fjb',
  'dementia',
  'sleepy joe',
  'lgb',
  'insurrection',
  'wer nagelt mich and die wand?',
  '@natalie.hernandex',
  '@__earn_with_amelia__',
  'do not visit my link',
  'do not visit my link,',
  'tim',
  'mental illness',
  'mentally ill',
  'wtf',
  'enough already',
  'perv',
  'preverse',
  'pushing',
  'pushin',
  'agenda',
  'anally',
  '🍭',
  'abominations',
  'bedroom',
  'click my username',
  'click my username.',
  '@tradeswithkaitlyn',
  'in the bathroom',
  'what r you doing after seeing my pic',
  'colonizer',
  'colonization',
  'the witch is dead',
  'rip bozo',
  'lizard',
  'ian cole',
  '@icole28',
  'leah hextall',
  'leah',
  'hextall',
  'fire the announcer',
  'ears + bleed',
  'ears + bleeding',
  'hextall + fire',
  'my ears',
  'nails + chalkboard',
  'child abuse',
  'leave the kids alone',
  'mutilation',
  'mutilate',
  'mutilated',
  'mutilating',
  'stunning + brave',
  'politic',
  'beautiful me',
  "it's my birthday today, i'll show myself.",
  '#fireleahhextall',
  'somebody help me!',
  'are you looking for me',
  "still don't know why i don't have a boyfriend",
  'i love to get excited',
  'how can i drive you crazy?',
  'does anyone like me here?',
  'allmylinks.com',
  'i want to go on a trip, will you keep me company?',
  'do i look like a kitty',
  "men's month",
  'mens month',
  "men's history",
  'mens history ',
  'kitchen',
  'fuckery',
  '$',
  '$$',
  '$$$',
  '$$$$',
  'reimer',
  'follow us',
  'lebron',
  'provorov',
  'am i attractive?',
  'come close to me',
  'sxfoxy',
  'silk like',
  'hacer',
  '💩',
  'hmu',
  ' i g n o r e',
  'funding',
  'hard time',
  'sxfxy',
  'bills',
  'hard times',
  'baddest',
  'asshole',
  'cocksuckers',
  '🍒',
  ' nude photos',
  ' difficult times',
  '5 grands',
  'faggots',
  'b a d d e r',
  'booty',
  'lukas',
  '“faith”',
  't h o t',
  'grandesexy',
  'paying off debt',
  'cindy',
  '“difficult times”',
  'signaling',
  'fuckers',
  'condom',
  'badder',
  'piss',
  'm a s t u r b a t e',
  'virtue',
  'blessed',
  'raping',
  'debts',
  'n u d e',
  'god did',
  'm a s t u r b a t i o n',
  'b a d',
  '“struggling”',
  '“depressed bad”',
  'cringe',
  'delete this ',
  'disgusting',
  'dislike button',
  'freak',
  'freaks',
  'gross ',
  'l ',
  'l post',
  'unfollow',
  'unfollowing ',
  'take this down',
  'delusional',
  'delusions',
  'sin',
  'only two genders',
  'delusion',
  'hell no',
  'bible',
  '#superstraight',
  'religion',
  'hate gays',
  'fags',
  'burn in hell',
  'ew',
  'they should burn',
  'kill all gays ',
  'kill the gays',
  'abomination',
  'need to die',
  'cucked ',
  'gay agenda',
  '#gayagenda',
  'nobody cares',
  "don't be gay",
  'mental hospital ',
  '$aggots',
  'god',
  'sickness',
  'psych ward',
  'push agenda',
  'keep politics out of',
  'Stick to hockey',
  'What is a woman',
  'memorial day',
  'unfollowed',
  'delete',
  'stick to football',
  'joke',
  'stop',
  'boo',
  'throat',
  'throats',
  'dinosaur month',
  'just focus on',
  'focus on football',
  'focus on hockey',
  'force',
  'bud lite',
  'bug light',
  'just play football',
  'gey',
  'Stay out of politics',
  '🚮',
  'fahg',
  'fahgs',
  'analbeads',
  'threw up',
  'woketard',
  'zesty',
  'stupid pride stuff',
  'dumbfuqs',
  'no place in sports',
  'ghey',
  'daily free picks',
  'referees blind',
  'warmups',
  'warmup',
  'warm up',
  'warm ups',
  'banned',
  'ban',
  'theme jersey',
  'themed jersey',
];

export const checkOnProhibitedPhrases = (value: string) =>
  Boolean(
    PROHIBITED_PHRASES.find(
      (word: string) => value.toLowerCase() === word.toLowerCase(),
    ),
  );
