import { FC } from 'react';

import { Box, Flex, Image, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  URLSearchParamsInit,
  createSearchParams,
  useNavigate,
} from 'react-router-dom';

import { tKeys } from '@/i18n';

import joinedIcon from '@/assets/icons/joined.svg';
import NhlImage from '@/assets/icons/nhl-shield.svg';

import { RightArrowButton } from '@/components/RightArrowButton';
import { RightPlusButton } from '@/components/RightPlusButton';

import { RoutesEnum } from '@/enums/routes.enum';
import { SearchParamsEnum } from '@/enums/searchParams.enum';

import { useAuthContext } from '@/hooks/useAuthContext';
import { useGameDate } from '@/hooks/useGameDate';
import { useJWT } from '@/hooks/useJWT';
import { useSettings } from '@/hooks/useSettings';

import { LeagueType } from '@/modules/Leagues/leagues.enums';
import { League } from '@/modules/Leagues/leagues.types';

import { createPath } from '@/routes/types';

interface LeaguesListProps {
  title: string;
  type?: LeagueType;
  leagues?: League[];
}

export const LeaguesList: FC<LeaguesListProps> = ({ title, leagues, type }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isMobile } = useSettings();
  const params =
    type &&
    ({
      [SearchParamsEnum.leagueType]: type,
    } as URLSearchParamsInit);
  const searchParams = createSearchParams(params).toString();
  const { withAuthCheck } = useAuthContext();
  const { isOver, isLive } = useGameDate();

  const handleSeeAllLeagues = () => {
    const path = createPath({ path: RoutesEnum.leagues });
    navigate({ pathname: path, search: searchParams });
  };

  const handleLeagueClick = (league_id: string | number) => {
    withAuthCheck(() => {
      navigate(
        createPath({
          path: RoutesEnum.leagueManage,
          params: { id: league_id },
        }),
      );
    });
  };

  return (
    <Flex align='center' direction='column' w='100%'>
      <Box borderRadius='8px' overflow='hidden' w='100%'>
        <Box bg='primaryDarkGradient' boxShadow='containerShadow' p='16px'>
          <Text
            fontSize='18px'
            fontWeight='700'
            textAlign='center'
            textTransform='uppercase'
          >
            {title}
          </Text>
        </Box>
        <Flex
          bg='rgba(33, 65, 88, 0.50)'
          justify='space-between'
          p='8px 44px 8px 60px'
        >
          <Text>{t(tKeys.name)}</Text>
          <Text>{isLive ? t(tKeys.entry) : ''}</Text>
        </Flex>

        <Flex direction='column' gap='4px'>
          {leagues?.map((league, idx) => (
            <Flex
              align='center'
              bg='rgba(18, 18, 18, 0.50)'
              h='56px'
              justify='space-between'
              key={idx}
              p='8px 12px'
            >
              <Flex
                _hover={{
                  textDecoration: 'underline',
                }}
                align='center'
                cursor='pointer'
                gap='8px'
                onClick={() =>
                  navigate(
                    createPath({
                      path: RoutesEnum.leagueManage,
                      params: { id: league?.league_id },
                    }),
                  )
                }
                paddingRight={2}
              >
                <Image
                  alt='league-image'
                  fallbackSrc={NhlImage}
                  src={league.icon}
                  w='40px'
                />
                {isMobile
                  ? league.name.length > 30
                    ? `${league.name.slice(0, 30)}...`
                    : league.name
                  : league.name.length > 50
                    ? `${league.name.slice(0, 50)}...`
                    : league.name}
              </Flex>
              {league.is_member ? (
                <Button
                  alignItems='center'
                  borderRadius='60px'
                  fontSize={15}
                  isDisabled={isOver}
                  maxW={['96px', '120px']}
                  onClick={() => handleLeagueClick(league?.league_id)}
                  paddingBottom={1}
                  paddingLeft={4}
                  paddingRight={4}
                  paddingTop={1}
                  rightIcon={<Image src={joinedIcon} />}
                  variant='success'
                >
                  {t(tKeys.joined)}
                </Button>
              ) : (
                <RightPlusButton
                  h='33px'
                  isDisabled={isOver}
                  maxW={['96px', '120px']}
                  onClick={() =>
                    navigate(
                      createPath({
                        path: RoutesEnum.leagueManage,
                        params: { id: league?.league_id },
                      }),
                    )
                  }
                >
                  {t(tKeys.join)}
                </RightPlusButton>
              )}
            </Flex>
          ))}
        </Flex>
      </Box>

      <RightArrowButton
        isDisabled={isOver}
        mt='16px'
        onClick={handleSeeAllLeagues}
      >
        {t(tKeys.seeAllLeagues)}
      </RightArrowButton>
    </Flex>
  );
};
