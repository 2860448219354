import React, { PropsWithChildren } from 'react';

import { Flex, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { useGameDate } from '@/hooks/useGameDate';
import { useSettings } from '@/hooks/useSettings';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';
import {
  CHANGE_BONUS_MODAL_VISIBILITY,
  CHANGE_BONUS_MODAL_VISIBILITY_SC,
  selectBracket,
} from '@/modules/Bracket/bracket.slice';
import { BracketMode } from '@/modules/Bracket/brackets.enums';
import { BonusModal } from '@/modules/Bracket/components/BonusModal';
import { ModalEditButton } from '@/modules/Bracket/components/ModalEditButton';

export const EditConferenceBonus: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useSettings();
  const { bracketMode } = useSelector(selectBracket);
  const { pathname } = useLocation();
  const {
    isOpen: isOpenBonus,
    onClose: onCloseBonus,
    onOpen: onOpenBonus,
  } = useDisclosure({
    id: 'bonus',
  });

  const onWestClick = () => {
    dispatch(
      pathname === bracketsSecondChancePath()
        ? CHANGE_BONUS_MODAL_VISIBILITY_SC(201)
        : CHANGE_BONUS_MODAL_VISIBILITY(101),
    ); // Set content in modal by match_id from west conf
    onOpenBonus();
  };

  const onEastClick = () => {
    dispatch(
      pathname === bracketsSecondChancePath()
        ? CHANGE_BONUS_MODAL_VISIBILITY_SC(203)
        : CHANGE_BONUS_MODAL_VISIBILITY(105),
    ); // Set content in modal by match_id from east conf
    onOpenBonus();
  };

  return (
    <>
      <ModalEditButton
        gap={isMobile ? '4px' : '10px'}
        justifyContent='center'
        onClick={onWestClick}
        w={isMobile ? '50%' : 'auto'}
      >
        {t(tKeys.wcBonus)}
      </ModalEditButton>

      {children}

      <ModalEditButton
        gap={isMobile ? '4px' : '10px'}
        justifyContent='center'
        onClick={onEastClick}
        w={isMobile ? '50%' : 'auto'}
      >
        {t(tKeys.ecBonus)}
      </ModalEditButton>
      <BonusModal isOpen={isOpenBonus} onClose={onCloseBonus} />
    </>
  );
};
