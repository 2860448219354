import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
// Dayjs - Locales
import 'dayjs/locale/fr-ca';
// Extend Dayjs
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);

export const currentMonthNotEqualGiven = (isoDate: string) => {
  const givenDate = dayjs(isoDate);
  const currentDate = dayjs();
  return givenDate.month() !== currentDate.month();
};

export const isDateInPast = (date?: string) => {
  if (!date) return false;
  const dateInput = dayjs(date);
  const now = dayjs(Date.now());
  return dateInput.isSameOrBefore(now);
};
