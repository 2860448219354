import { createApi } from '@reduxjs/toolkit/query/react';

import { API_AUTH_URL, AUTH_APP_CODE, GEOLOCATION_KEY } from '@/core/env';

import { ApiTagsEnum } from '@/enums/apiTags.enum';

import {
  GetCoordinatesRes,
  GeyUserGeoPositionRes,
  LoginReqBody,
  LoginResponse,
} from '@/types/domain';

import { baseQueryWithRedirect } from '@/utils/api.util';

export const api = createApi({
  reducerPath: 'mainApi',
  baseQuery: baseQueryWithRedirect,
  tagTypes: Object.values(ApiTagsEnum),
  endpoints: (builder) => ({
    getJWT: builder.mutation<LoginResponse, LoginReqBody>({
      query: (body) => ({
        url: '/login',
        method: 'POST',
        body,
      }),
    }),
    getSSO: builder.query<void, void>({
      query: () => ({
        url: `${API_AUTH_URL}/sso/nhl?appCode=${AUTH_APP_CODE}`,
      }),
    }),
    checkIsJWTValid: builder.mutation<{ valid: boolean }, void>({
      query: () => ({
        url: '/is-valid',
        method: 'POST',
      }),
    }),
    fetchCoordinates: builder.mutation<GetCoordinatesRes, void>({
      query: () => ({
        url: `https://www.googleapis.com/geolocation/v1/geolocate?key=${GEOLOCATION_KEY}`,
        method: 'POST',
      }),
    }),
    getUserGeo: builder.query<
      GeyUserGeoPositionRes,
      GetCoordinatesRes['location']
    >({
      query: ({ lat, lng }) => ({
        url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=false&key=${GEOLOCATION_KEY}`,
      }),
    }),
  }),
});

export const {
  useLazyGetSSOQuery,
  useGetJWTMutation,
  useCheckIsJWTValidMutation,
  useFetchCoordinatesMutation,
  useGetUserGeoQuery,
  useLazyGetUserGeoQuery,
} = api;
