import React from 'react';

import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  ELIGIBLE_LEAGUE_ID,
  ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
  NOT_ELIGIBLE_LEAGUE_ID,
  NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID,
} from '@/core/env';
import { tKeys } from '@/i18n';

import { ReactComponent as LogoutIcon } from '@/assets/icons/logout.svg';

import { Footer } from '@/components/Layout/Footer';

import { PageLoader } from '@/elements/PageLoader';

import { RoutesEnum } from '@/enums/routes.enum';

import { useSettings } from '@/hooks/useSettings';
import { useToast } from '@/hooks/useToast';

import { LeagueManagePrizes } from '@/modules/LeagueManage/components/LeagueManagePrizes';
import { LeaderboardFilter } from '@/modules/LeagueManage/containers/LeaderboardFilter';
import { LeagueManageTable } from '@/modules/LeagueManage/containers/LeagueManageTable';
import { MembersFilter } from '@/modules/LeagueManage/containers/MembersFilter';
import { MembersTable } from '@/modules/LeagueManage/containers/MembersTable';
import { getLeagueData } from '@/modules/LeagueManage/leagueManage.selectors';
import { LeagueType } from '@/modules/Leagues/leagues.enums';

import { createPath } from '@/routes/types';

import { hiddenScrollbarStyles } from '@/utils/common.util';

import { LeagueInfo } from '../containers/LeagueInfo';
import { ShareLeague } from '../containers/ShareLeague';
import { useLeaveLeagueMutation } from '../leagueManage.api';

import { LeagueActionsBar } from './LeagueActionsBar';
import { LeaveLeagueModal } from './LeaveLeagueModal';
import AdsBanner from '@/components/AdsBanner';

export const LeagueManage: React.FC = () => {
  const leagueData = useSelector(getLeagueData);
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isMobile, isLgDesktop } = useSettings();

  return (
    <Box
      height={isLgDesktop ? '650px' : '100%'}
      maxW={isLgDesktop ? '1000px' : '100%'}
      mt={isMobile ? '87px' : '0px'}
      overflow={isLgDesktop ? 'auto' : 'unset'}
      sx={hiddenScrollbarStyles}
      width='100%'
    >
      <LeagueInfo />
      {leagueData?.type !== LeagueType.Friends && <LeagueManagePrizes />}
      {!leagueData?.prize &&
        leagueData?.type === LeagueType.Friends &&
        !isMobile && <LeagueActionsBar />}

      <LeagueManageTable />
      <ShareLeague />

      {leagueData?.league_id != ELIGIBLE_LEAGUE_ID &&
        leagueData?.league_id != NOT_ELIGIBLE_LEAGUE_ID &&
        leagueData?.league_id != ELIGIBLE_SECOND_CHANCE_LEAGUE_ID &&
        leagueData?.league_id != NOT_ELIGIBLE_SECOND_CHANCE_LEAGUE_ID &&
        (leagueData?.is_member || leagueData?.is_owner) && (
          <Flex justify='center' p='14px'>
            <Button
              h='36px'
              maxW='157px'
              onClick={onOpen}
              rightIcon={<LogoutIcon />}
              variant='error'
            >
              {leagueData?.is_owner
                ? t(tKeys.deleteLeague)
                : t(tKeys.leaveLeague)}
            </Button>
          </Flex>
        )}
      <AdsBanner
        my='30px'
        slotId='mobile-bottom-banner-ad'
        slotSize={isMobile ? [320, 50] : [728, 90]}
      />
      <Footer />

      <LeaveLeagueModal {...{ isOpen, onClose, onOpen }} />
    </Box>
  );
};
