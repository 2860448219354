import React, { SVGProps, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { PathPattern, useLocation } from 'react-router-dom';

import { GAMEZONE_URL } from '@/core/env';
import { tKeys } from '@/i18n';

import { CalendarIcon } from '@/assets/icon-components/Calendar.icon';
import { CircleInfoIcon } from '@/assets/icon-components/CircleInfo.icon';
import { GeoPinIcon } from '@/assets/icon-components/GeoPin.icon';
import { HierarchyIcon } from '@/assets/icon-components/Hierarchy.icon';
import { MedalLeaderboardIcon } from '@/assets/icon-components/MedalLeaderboard.icon';
import { UsersGroupIcon } from '@/assets/icon-components/UsersGroup.icon';

import { RoutesEnum } from '@/enums/routes.enum';

import { useGameDate } from '@/hooks/useGameDate';

import { createPath } from '@/routes/types';
import { isPathEqualsToPathname } from '@/routes/utils';

export interface NavigationLinkType {
  label: string;
  selected?: boolean;
  renderIcon?: (props?: SVGProps<any>) => React.ReactNode;
  items?: NavigationLinkType[];
  to?: RoutesEnum | string;
}
export const useNavigation = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isComing } = useGameDate();

  const getIsRouteSelected = useCallback(
    (route: string, params?: Partial<PathPattern>) =>
      isPathEqualsToPathname(route, pathname, params),
    [pathname],
  );

  const getIsSelected = useCallback(
    (route: string | string[], params?: Partial<PathPattern>) =>
      Array.isArray(route)
        ? route.some((item) => getIsRouteSelected(item, params))
        : getIsRouteSelected(route, params),
    [getIsRouteSelected],
  );

  const links = useMemo<NavigationLinkType[]>(
    () => [
      {
        label: t(tKeys.home),
        selected: getIsSelected(RoutesEnum.home),
        to: createPath({ path: RoutesEnum.home }),
        renderIcon: (props) => <CalendarIcon {...props} />,
      },
      {
        label: t(tKeys.brackets),
        selected: getIsSelected(
          [
            RoutesEnum.bracketsPreRegistration,
            RoutesEnum.bracketsNew,
            RoutesEnum.brackets,
            RoutesEnum.bracketsSpecific,
            RoutesEnum.bracketsList,
          ],
          {
            end: false,
          },
        ),
        to: createPath({
          path: isComing
            ? RoutesEnum.bracketsPreRegistration
            : RoutesEnum.bracketsList,
        }),
        renderIcon: (props) => <HierarchyIcon {...props} />,
      },
      {
        label: t(tKeys.leagues),
        to: createPath({ path: RoutesEnum.leagues }),
        selected: getIsSelected(RoutesEnum.leagues),
        renderIcon: (props) => <GeoPinIcon {...props} />,
      },
      {
        label: t(tKeys.leaderboard),
        selected: getIsSelected(RoutesEnum.leaderboard),
        to: createPath({ path: RoutesEnum.leaderboard }),
        renderIcon: (props) => <MedalLeaderboardIcon {...props} />,
      },
      {
        label: t(tKeys.howToPlay),
        renderIcon: (props) => <CircleInfoIcon {...props} />,
        selected: getIsSelected([RoutesEnum.howToPlay, RoutesEnum.rules]),
        items: [
          {
            label: t(tKeys.howToPlay),
            to: createPath({ path: RoutesEnum.howToPlay }),
          },
          {
            label: t(tKeys.rules),
            to: createPath({ path: RoutesEnum.rules }),
          },
          /*,
          {
            label: t(tKeys.prizes),
            to: RoutesEnum.prizes,
          },
          */
        ],
      },
      {
        label: t(tKeys.gameZone),
        to: GAMEZONE_URL,
        renderIcon: (props) => <UsersGroupIcon {...props} />,
      },
    ],
    [isComing, getIsSelected, t],
  );

  return { links };
};
