import { Box, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/i18n';

import { nhlThinScrollbarStyles } from '@/utils/common.util';

export const OfficialRules = () => {
  const { t } = useTranslation();

  return (
    <Box
      bg='rgba(1, 2, 3, 0.50)'
      maxH='440px'
      overflowY='auto'
      p='24px'
      sx={nhlThinScrollbarStyles}
    >
      <Heading as='h2' my='16px' textAlign='center'>
        {t(tKeys.rulesTitle)}
      </Heading>

      <Heading as='h2' my='16px' textAlign='center'>
        {t(tKeys.rulesSubtilte)}
      </Heading>

      <Text my='16px'>
        <strong>{t(tKeys.rulesP1)}</strong>
      </Text>
      <Text my='16px'> {t(tKeys.rulesP2)}</Text>
      <Text my='16px'>{t(tKeys.rulesS1)}</Text>
      <Text my='16px'>{t(tKeys.rulesS2)}</Text>
      <Text my='16px'>{t(tKeys.rulesS3)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P1)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P2)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P3)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P4)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P5)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P6)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P7)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P8)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P9)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P10)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P11)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P12)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P13)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P14)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P15)}</Text>
      <Text my='16px'>{t(tKeys.rulesS4P16)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P1)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P2)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P3)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P4)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P5)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P6)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P7)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P8)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P9)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P10)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P11)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P12)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P13)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P14)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P15)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P16)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P17)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P18)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P19)}</Text>
      <Text my='16px'>{t(tKeys.rulesS5P20)}</Text>
      <Text my='16px'>{t(tKeys.rulesS6)}</Text>
      <Text my='16px'>{t(tKeys.rulesS7)}</Text>
      <Text my='16px'>{t(tKeys.rulesS8P1)}</Text>
      <Text my='16px'>{t(tKeys.rulesS8P2)}</Text>
      <Text my='16px'>{t(tKeys.rulesS8P3)}</Text>
      <Text my='16px'>{t(tKeys.rulesS9)}</Text>
      <Text my='16px'> {t(tKeys.rulesS10P1)}</Text>
      <Text my='16px'>{t(tKeys.rulesS10P2)}</Text>
      <Text my='16px'>{t(tKeys.rulesS10P3)}</Text>
      <Text my='16px'>{t(tKeys.rulesS10P4)}</Text>
      <Text my='16px'>{t(tKeys.rulesS10P5)}</Text>
      <Text my='16px'>{t(tKeys.rulesS10P6)}</Text>
      <Text my='16px'>{t(tKeys.rulesS10P7)}</Text>
      <Text my='16px'>{t(tKeys.rulesS10P8)}</Text>
      <Text my='16px'>{t(tKeys.rulesS11)}</Text>
      <Text my='16px'>{t(tKeys.rulesS12)}</Text>
      <Text my='16px'>{t(tKeys.rulesS13)}</Text>
      <Text my='16px'>{t(tKeys.rulesS14)}</Text>
      <Text my='16px'>{t(tKeys.rulesS15P1)}</Text>
      <Text my='16px'>{t(tKeys.rulesS15P2)}</Text>
      <Text my='16px'>{t(tKeys.rulesS16)}</Text>
      <Text my='16px'>{t(tKeys.rulesS17)}</Text>
      <Text my='16px'>{t(tKeys.rulesS18P1)}</Text>
      <Text my='16px'>{t(tKeys.rulesS18P2)}</Text>
      <Text my='16px'>{t(tKeys.rulesS18P3)}</Text>
    </Box>
  );
};
