import { useEffect, type ReactNode } from 'react';

import { StorageKeysEnum } from '@/enums/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import {
  useFetchCoordinatesMutation,
  useGetUserGeoQuery,
  useLazyGetUserGeoQuery,
} from '@/services/api.service';

import { UserGeoPositionType } from '@/types/domain';

import { currentMonthNotEqualGiven } from '@/utils/date';
import { formatUserGeoPositionRes } from '@/utils/geo';

export const GeoPositionProvider = ({ children }: { children: ReactNode }) => {
  const { value: geoPosition, setItem: setGeoPosition } =
    useLocalStorage<UserGeoPositionType>(StorageKeysEnum.geoPosition);

  const [triggerFetch] = useFetchCoordinatesMutation();
  const [triggerGet] = useLazyGetUserGeoQuery();

  useEffect(() => {
    const handleGeo = async () => {
      const coordinates = await triggerFetch().unwrap();

      const res = await triggerGet({
        lat: coordinates.location.lat,
        lng: coordinates.location.lng,
      }).unwrap();

      const data = formatUserGeoPositionRes(res);

      setGeoPosition({ ...data, date: new Date().toISOString() });
    };

    //* Comment the first boolean to run in dev
    if (
      // process.env.NODE_ENV === 'production' &&
      !geoPosition ||
      currentMonthNotEqualGiven(geoPosition?.date)
    ) {
      handleGeo();
    }
  }, [geoPosition, setGeoPosition, triggerFetch, triggerGet]);

  return <>{children}</>;
};
