import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { ENABLED_LANGUAGES } from '../core/env';
import { StorageKeysEnum } from '../enums/storageKeys.enum';
import { getStorageItem } from '../utils/localStorage.util';

import cs from './locales/cs.json';
import de from './locales/de.json';
import en from './locales/en.json';
import fi from './locales/fi.json';
import fr from './locales/fr.json';
import sv from './locales/sv.json';

export const RESOURCES = {
  en: { translation: en },
  fr: { translation: fr },
  cs: { translation: cs },
  fi: { translation: fi },
  de: { translation: de },
  sv: { translation: sv },
};

export type TFunc = (key: string) => string;
export type tKeysUnion = keyof typeof DEFAULT_LANGUAGE;
export type tKeysType = { [key in tKeysUnion]: tKeysUnion };
export const tKeys = Object.fromEntries(
  Object.keys(en).map((key) => [key, key]),
) as tKeysType;

export const DEFAULT_LANGUAGE = en;

export const DEFAULT_LANGUAGE_KEY = 'en';

export type LanguageCodeType = keyof typeof RESOURCES;

export const enabledLanguages = (ENABLED_LANGUAGES?.split(',').filter(
  (lang: string) => Object.keys(RESOURCES).includes(lang),
) as LanguageCodeType[]) || [DEFAULT_LANGUAGE_KEY];

export const isValidLangInPath = (lang: string | undefined) =>
  enabledLanguages.includes(lang as (typeof enabledLanguages)[0]);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: RESOURCES,
    supportedLngs: enabledLanguages,
    lng:
      getStorageItem<string>(StorageKeysEnum.i18nextLng) ||
      DEFAULT_LANGUAGE_KEY,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
