import { FC, useEffect, useState } from 'react';

import { Button, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';

import { ModalWindow } from '@/elements/ModalWindow';

import { bracketsSecondChancePath } from '@/modules/Bracket/bracket.constants';

import {
  selectBracket,
  SET_EAST_BONUS_QUESTION_ANSWERED,
  SET_WEST_BONUS_QUESTION_ANSWERED,
} from '../../bracket.slice';
import { FirstRoundContent } from '../../components/BonusModal/FirstRoundContent';

export const BonusModal: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { entry, entrySC, bonusVisibility } = useSelector(selectBracket);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [scrollToTarget, setScrollToTarget] = useState(false);
  const isWestModal = bonusVisibility.west;
  const isEastModal = bonusVisibility.east;

  const onNextClick = () => {
    if (isWestModal) {
      dispatch(SET_WEST_BONUS_QUESTION_ANSWERED(true));
    }

    if (isEastModal) {
      dispatch(SET_EAST_BONUS_QUESTION_ANSWERED(true));
    }

    onClose();
    setScrollToTarget(true);
  };

  useEffect(() => {
    if (scrollToTarget) {
      const element = document.getElementById('round-second-east');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }
      setScrollToTarget(false);
    }
  }, [scrollToTarget]);

  const onCloseModal = () => {
    if (isWestModal) {
      dispatch(SET_WEST_BONUS_QUESTION_ANSWERED(false));
    }

    if (isEastModal) {
      dispatch(SET_EAST_BONUS_QUESTION_ANSWERED(false));
    }

    onClose();
  };

  const heading = isWestModal
    ? t(tKeys.westernConfBonusRound)
    : t(tKeys.easternConfBonusRound);

  const isDisabledWestBase =
    isWestModal &&
    (entry.match_101_match_played === 0 ||
      entry.match_102_match_played === 0 ||
      entry.match_103_match_played === 0 ||
      entry.match_104_match_played === 0);
  const isDisabledWestSC =
    isWestModal &&
    (entrySC.match_201_match_played === 0 ||
      entrySC.match_202_match_played === 0);

  const isDisabledEastBase =
    isEastModal &&
    (entry.match_105_match_played === 0 ||
      entry.match_106_match_played === 0 ||
      entry.match_107_match_played === 0 ||
      entry.match_108_match_played === 0);
  const isDisabledEastSC =
    isEastModal &&
    (entrySC.match_203_match_played === 0 ||
      entrySC.match_204_match_played === 0);

  const isDisabledWest =
    pathname === bracketsSecondChancePath()
      ? isDisabledWestSC
      : isDisabledWestBase;
  const isDisabledEast =
    pathname === bracketsSecondChancePath()
      ? isDisabledEastSC
      : isDisabledEastBase;

  return (
    <ModalWindow
      BodyContent={
        <>
          <Heading mt={['5px', '15px']} size='sm' textAlign='center'>
            {heading}
          </Heading>
          <Text m={['3px 0', '15px 0 30px']} textAlign='center'>
            {t(tKeys.predict)}:
          </Text>

          <FirstRoundContent />
        </>
      }
      FooterContent={
        <>
          <Button
            colorScheme='green'
            isDisabled={isDisabledWest || isDisabledEast}
            mr={3}
            onClick={onNextClick}
          >
            {t(tKeys.next)}
          </Button>
        </>
      }
      closeOnOverlayClick={false}
      hasCloseButton={false}
      isOpen={isOpen}
      onClose={onCloseModal}
    />
  );
};
