export enum LeagueType {
  Prize = 'PRIZE',
  Club = 'CLUB',
  Partner = 'PARTNER',
  MyLeagues = 'MYLEAGUES',
  Friends = 'FRIENDS',
}

export enum LeagueIconKey {
  AnaheimDucks = 'AnaheimDucks',
  UtahHockeyClub = 'UtahHockeyClub',
  BostonBruins = 'BostonBruins',
  BuffaloSabres = 'BuffaloSabres',
  CalgaryFlames = 'CalgaryFlames',
  CarolinaHurricanes = 'CarolinaHurricanes',
  ChicagoBlackhawks = 'ChicagoBlackhawks',
  ColoradoAvalanche = 'ColoradoAvalanche',
  ColumbusBlueJackets = 'ColumbusBlueJackets',
  DallasStars = 'DallasStars',
  DetroitRedWings = 'DetroitRedWings',
  EdmontonOilers = 'EdmontonOilers',
  FloridaPanthers = 'FloridaPanthers',
  HockeySkate = 'HockeySkate',
  LosAngelesKings = 'LosAngelesKings',
  MinnesotaWild = 'MinnesotaWild',
  MontrealCanadiens = 'MontrealCanadiens',
  NashvillePredators = 'NashvillePredators',
  NewYorkRangers = 'NewYorkRangers',
  NewJerseyDevils = 'NewJerseyDevils',
  NewYorkIslanders = 'NewYorkIslanders',
  NhlLeague = 'NhlLeague',
  OttawaSenators = 'OttawaSenators',
  PhiladelphiaFlyers = 'PhiladelphiaFlyers',
  PittsburghPenguins = 'PittsburghPenguins',
  SanJoseSharks = 'SanJoseSharks',
  SeattleKraken = 'SeattleKraken',
  StLouisBlues = 'StLouisBlues',
  StanleyCup = 'StanleyCup',
  TampaBayLightning = 'TampaBayLightning',
  TorontoMapleLeafs = 'TorontoMapleLeafs',
  VancouverCanucks = 'VancouverCanucks',
  VegasGoldenKnights = 'VegasGoldenKnights',
  WashingtonCapitals = 'WashingtonCapitals',
  WinnipegJets = 'WinnipegJets',
}
