import { FC } from 'react';

import { Box, Flex } from '@chakra-ui/react';

export const CompareLine: FC<{
  color1: string;
  color2: string;
  percent1: number;
  percent2: number;
}> = ({ color1, color2, percent1, percent2 }) => {
  return (
    <>
      <Flex gap='2px' h='12px' w={['200px', '100%']}>
        <Box
          bgColor={color1}
          borderRadius='8px 0 0 8px'
          h='100%'
          w={`${percent1}%`}
        />
        <Box
          bgColor={color2}
          borderRadius='0 8px 8px 0'
          h='100%'
          w={`${percent2}%`}
        />
      </Flex>
    </>
  );
};
