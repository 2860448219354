import { Helmet } from 'react-helmet-async';

import { GuideLayout } from '@/components/GuideLayout';

import { Guide } from '@/modules/Main/components/Guide';

const HowToPlayPage = () => {
  return (
    <>
      <Helmet>
        <title>How to Play | Bracket Challenge</title>
        <meta
          content='The official rules and how to play instructions of the 2025 Stanley Cup Playoffs Bracket Challenge.'
          name='description'
        />
      </Helmet>
      <GuideLayout>
        <Guide mt='20px' />
      </GuideLayout>
    </>
  );
};

export default HowToPlayPage;
