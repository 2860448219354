import { FC } from 'react';

import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { tKeys } from '@/i18n';

import {
  bracketsSecondChancePath,
  MAX_BRACKET_SELECTIONS_AMOUNT,
  MAX_SC_BRACKET_SELECTIONS_AMOUNT,
} from '@/modules/Bracket/bracket.constants';

export const StepsLabel: FC<{ step: number }> = ({ step }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const MAX_AMOUNT =
    pathname === bracketsSecondChancePath()
      ? MAX_SC_BRACKET_SELECTIONS_AMOUNT
      : MAX_BRACKET_SELECTIONS_AMOUNT;
  return (
    <Flex align='center' gap='10px'>
      {step === MAX_AMOUNT && <GreenCheckIcon />}
      <Text fontSize={['14px', '16px']} whiteSpace='nowrap'>
        {t(tKeys.selectionsMade, { step, total: MAX_AMOUNT })}
      </Text>
    </Flex>
  );
};

const GreenCheckIcon = () => (
  <svg
    fill='none'
    height='24'
    viewBox='0 0 25 24'
    width='25'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.5 2C6.98 2 2.5 6.48 2.5 12C2.5 17.52 6.98 22 12.5 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.5 2ZM10.5 17L5.5 12L6.91 10.59L10.5 14.17L18.09 6.58L19.5 8L10.5 17Z'
      fill='#54C872'
    />
  </svg>
);
